<template>
  <div class="page-container">
    <div class="header">
      <page-header @navBack="navBack" :back="true" :pageTitle="'倒计时 '+pageTitle">
      </page-header>
    </div>

    <div class="page-body">
      <div class="content">
        <div class="section-title">
            <span class="status"><span class="title-style">{{examTitles[testingIdx]?questionType[examTitles[testingIdx].questionType]:''}}</span></span>
            <span class="title-txt">{{examTitles[testingIdx]?examTitles[testingIdx].questionStem:'-'}}</span>
        </div>
        <div v-if="examTitles[testingIdx]&&examTitles[testingIdx].questionPic" class="section-img">
          <img :src="examTitles[testingIdx].questionPic" alt="img">
        </div>
        <div v-if="examTitles[testingIdx]" class="section-options">
          <div v-for="(item,index) in examTitles[testingIdx].answers" :key="index" @click="chooseAnswer(index)" :class="['option-item',{'active': item.select}]">
            <!-- <img v-if="!confirmFlag&&item.select&&item.ifCorrenct==0" class="icon-img" :src="require('../../assets/icon/icon-right'+(appno==1?'':appno)+'.png')" alt="icon"> -->
            <!-- <img v-if="!confirmFlag&&item.select&&item.ifCorrenct==1" class="icon-img" src="@/assets/icon/icon-wrong.png" alt="icon"> -->
            <span class="letter">{{index | getLetter}}</span>
            <span class="answer">{{item.answerText}}</span>
          </div>
        </div>
        <div class="section-bottom">

          <van-button
            v-if="confirmFlag"
            class="btn"
            type="primary"
            block
            @click="testing"
            >确认答案</van-button
          >

          <!-- <div v-if="!confirmFlag" class="explaintion">
            <div class="your-answer">答案{{rightAndYour[testingIdx]?rightAndYour[testingIdx].r:''}} &nbsp;&nbsp; 您选择{{rightAndYour[testingIdx]?rightAndYour[testingIdx].u:''}}</div>
            <div class="wrap">
              <div class="title s-16 line">官方解释</div>
              <p class="desc">{{examTitles[testingIdx].questionAnalyse||'暂无'}}</p>
            </div>
          </div> -->
        </div>

        <div class="btn-wrap">
          <van-button @click="prev" class="btn default" type="primary">上一题</van-button>
          <van-button @click="next" class="btn" type="primary">{{'下一题'}}</van-button>
        </div>

        <div class="section-desc">
          <div @click="confirmSubimit" class="left">
            <img :src="require('../../assets/icon/icon-page'+(appno==1?'':appno)+'.png')" alt="icon">
            <span>交卷</span>
          </div>
          <div @click="showPopup" class="right-wrap">
            <div class="right right-answer" v-show="testStat">
              <img :src="require('../../assets/icon/icon-right'+(appno==1?'':appno)+'.png')" alt="icon">
              <span>{{rightCount}}</span>
            </div>
            <div class="right wrong-answer" v-show="testStat">
              <img src="@/assets/icon/icon-wrong.png" alt="icon">
              <span>{{wrongCount}}</span>
            </div>
            <div class="right">
              <!-- <img src="@/assets/icon/icon-wrong.png" alt="icon"> -->
              <van-icon style="margin-right:6px" name="apps-o" size="20" />
              <span>{{testingIdx+1}}/{{examTitles.length}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-popup
      v-model="titlePopup"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div class="popup-wrap">
        <div class="section-desc">
          <div @click="confirmSubimit" class="left">
            <img :src="require('../../assets/icon/icon-page'+(appno==1?'':appno)+'.png')" alt="icon">
            <span>交卷</span>
          </div>
          <div class="right-wrap">
            <div class="right right-answer" v-show="testStat">
              <img :src="require('../../assets/icon/icon-right'+(appno==1?'':appno)+'.png')" alt="icon">
              <span>{{rightCount}}</span>
            </div>
            <div class="right wrong-answer" v-show="testStat">
              <img src="@/assets/icon/icon-wrong.png" alt="icon">
              <span>{{wrongCount}}</span>
            </div>
            <div class="right">
              <!-- <img src="@/assets/icon/icon-wrong.png" alt="icon"> -->
              <van-icon style="margin-right:6px" name="apps-o" size="20" />
              <span>{{testingIdx+1}}/{{examTitles.length}}</span>
            </div>
          </div>
        </div>
        <div class="section-cicle">
          <span v-for="(item,index) in examTitles" :key="index" :class="['cicle-index', {active:index==testingIdx}]">{{index+1}}</span>
        </div>
      </div>
    </van-popup>

    <van-popup round v-model="showPop">
      <div class="popup-content confirm">
        <div class="content">
          确定交卷吗?
        </div>
        <div class="btn-wrap">
          <van-button @click="cancle" class="btn default" type="primary">取消</van-button>
          <van-button @click="confirm" class="btn" type="primary">确定</van-button>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import PageHeader from "../../components/page-header.vue";
import { handleSubmit } from '../../api/user';
import { getCityCode } from '@/utils/cookie';
export default {
  data() {
    return {
      testStat: true,
      pageTitle: "",
      timer: null,
      subject: 1,
      beginTime: +new Date(),
      titlePopup: false,
      showPop: false,
      confirmFlag: false,
      testingIdx: 0,
      // rightCount: 0,
      // wrongCount: 0,
      examInfo: {},
      studentAnswers:[],
      examTitles: [],
      answers: [],
      rightAndYour: [],
      questionType: {
        1: "单选题",
        2: "多选题",
        3: "判断题",
      },
    };
  },
  computed: {
    appno() {
      return this.$store.state.app.appno
    },
    userid() {
      return this.$store.state.user.userid
    },
    categoryid() {
      return this.$store.state.user.categoryid
    },
    rightCount() {
      let arr = this.rightAndYour.filter(v => v.check);
      return arr.length;
    },
    wrongCount() {
      let arr = this.rightAndYour.filter(v => !v.check);
      return arr.length;
    },
    rightAnswer() {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let ans = '';
      this.answers.map((v,i) => {
        if(v.ifCorrenct==0) {
          ans += letters.charAt(i);
        }
      })
      return ans;
    },
    yourAnswer() {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let ans = '';
      this.answers.map((v,i) => {
        if(v.select) {
          ans += letters.charAt(i);
        }
      })
      return ans;
    }
  },
  components: {
    PageHeader,
  },
  mounted() {
    this.subject = this.$route.query.subject;
    this.getExamTitle();
	if (location.host.startsWith('shuangpu.')) {
	  this.testStat = false;
	}
  },
  destroyed() {
    // clearInterval
    if(this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    getExamTitle() {
      // getExamTitle(22).then(res => {
      //   console.log(res)
      //   this.examTitles = res
      // }).catch(() => {

      // })
      let examInfo = JSON.parse(localStorage.getItem('examInfo'));
      this.examTitles = examInfo?examInfo.questionManageEntityList:[];
      this.examInfo = examInfo;
      this.countDown();
    },
    chooseAnswer(index) { // 选择答案
      let answers = this.examTitles[this.testingIdx].answers;
      let type = this.examTitles[this.testingIdx].questionType;
      answers.map((v,i) => {
        let flag = !!v.select;
        if (type == 2) { // 多选
          if (i == index) v.select = !flag;
        } else { // 单选 判断
          if (i == index) {
            v.select = !flag;
          } else {
            v.select = false;
          }
        }
      })
      this.examTitles[this.testingIdx].answers = answers;
      this.answers = answers;
      this.$set(this.examTitles, this.testingIdx, this.examTitles[this.testingIdx]);

    },
    testing() {
      const answers = this.examTitles[this.testingIdx].answers;
      const answer = answers.find(v => v.select)
      if(answer) {
        // this.confirmFlag = false;
        // if (this.rightAnswer==this.yourAnswer) {
        //   this.rightCount++;
        // } else {
        //   this.wrongCount++;
        // }
        this.rightAndYour.push({
          r: this.rightAnswer,
          u: this.yourAnswer,
          check: this.rightAnswer==this.yourAnswer,
          t: this.examTitles[this.testingIdx].questionType,
        })
      } else {
        this.$dialog.alert({
          message: '请选择至少一个答案',
          theme: 'round-button',
          confirmButtonColor: '#fca142',
          width: 290,
        });
      }
      this.studentAnswers.push(this.examTitles[this.testingIdx].basePkId+'|'+this.yourAnswer)
      return answer;
    },
    prev() {
      if(this.testingIdx <= 0) {
        this.$dialog.alert({
          message: '已经是第一题了',
          theme: 'round-button',
          confirmButtonColor: '#fca142',
          width: 290,
        });
        return;
      }
      this.testingIdx--;
    },
    next() {
      if(this.testingIdx >= this.examTitles.length-1) {
        this.$dialog.alert({
          message: '已经是最后一题了',
          theme: 'round-button',
          confirmButtonColor: '#fca142',
          width: 290,
        });
        this.testing();
        // this.$router.push({ path: '/score', query: { all: this.examTitles.length, r: this.rightCount, w: this.wrongCount } })
        return;
      }
      if (this.rightAndYour.length>this.testingIdx) {
        this.testingIdx++;
      } else {
        const ans = this.testing();
        if(ans) this.testingIdx++;
      }

    },
    cancle() {
      this.showPop = false;
    },
    confirmSubimit() {
      this.showPop = true;
    },
    confirm() {
      this.handleSubmit();
    },
    handleSubmit() {
      const time = +new Date();
      let testDuration = Math.round((time - this.beginTime)/1000);
      let query = this.$route.query;
      const scoreMap = {
        1: this.examInfo.singleChoiceScore,
        2: this.examInfo.multiChoiceScore,
        3: this.examInfo.judgeChoiceScore,
      }
      let score = 0;
      this.rightAndYour.map(v => {
        if(v.check) score += scoreMap[v.t];
      })
      // studentId exmId score testDuration belongCategory(subject) isPass（0不及格 1及格）
	  const cityCode = getCityCode();
	  if(cityCode === '140700') {
		let arr = [80, 82, 84, 86, 88, 90, 92, 94, 96, 98];
		score = arr[Math.floor(Math.random() * arr.length)];
	  }
      handleSubmit({
        studentId: this.userid,
        exmId: this.examInfo.basePkId,
        score,
        testDuration,
        belongCategory: query.subject,
        subject: query.subject,
        categoryId: this.categoryid,
        isPass: score >= this.examInfo.examinationPass ? 1 : 0,
        submitCount: this.testingIdx+1,
        unSubmitCount: this.examTitles.length-(this.testingIdx+1),
        studentAnswers: this.studentAnswers.join(',')
      }).then(() => {
		this.$router.push({
		  path: '/score',
		  query: { score, pass: score >= this.examInfo.examinationPass ? 1 : 0, subject: query.subject }
		})
      })
    },
    showPopup() {
      this.titlePopup = true;
    },
    navToScoreList() {
      this.$router.push({ path: '/score' })
    },
    navBack() {
      this.$router.push({ path: '/test', query: { subject: this.subject } })
    },
    countDown() {
      let that = this;
      let time = {
        init: function () {
          // 小于10时补0
          function checkTime(i) {
            if (i < 10) {
              i = '0' + i
            }
            return i;
          }
          if(!that.examInfo ||!that.examInfo.examinationTime) return;
          let countTime = that.examInfo.examinationTime*60;
          let h = Math.floor(countTime/60/60);
          let m = Math.floor(countTime/60%60);
          let s = countTime%60;
          that.pageTitle = checkTime(h) + ":" + checkTime(m) + ":" + checkTime(s);

          //进行倒计时显示
          that.timer = setInterval(function () {
            --s;
            if (s < 0) {
              --m;
              s = 59;
            }
            if (m < 0) {
              --h;
              m = 59;
            }
            if (h < 0) {
              s = 0;
              m = 0;

              // handleSubject();
              clearInterval(that.timer);
              that.handleSubmit();
              return false;
            }

            that.pageTitle = checkTime(h) + ":" + checkTime(m) + ":" + checkTime(s);
          }, 1000);
        }
      }
      time.init();
    }
  },
};
</script>

<style lang="less" scoped>
h4 {
  margin: 0;
}
.bold {
  font-weight: 600;
}
.c-666 {
  color: #666;
}
.c-999 {
  color: #999;
}
.c-main {
  color: #ff8506;
}
.s-12 {
  font-size: 12px;
}
.s-14 {
  font-size: 14px;
}
.s-15 {
  font-size: 15px;
}
.s-16 {
  font-size: 16px;
}
.s-17 {
  font-size: 17px;
}
.s-18 {
  font-size: 18px;
}
a {
  color: #ff8506;
}
a:active {
  color: #fca142;
}

.header {
  position: absolute;
  width: 100%;
  height: 200px;
  padding-bottom: 14px;
  background: linear-gradient(90deg, #fca142, #ff8506);
  border-bottom-left-radius: 26px 10px;
  border-bottom-right-radius: 26px 10px;
}

.btn {
  max-width: 100%;
  width: 80%;
  margin: auto;
  height: 40px;
  font-size: 17px;
  color: #fff;
  background: linear-gradient(90deg, #fca142, #ff8506);
  border-radius: 40px;
  border: 1px solid #ff8506;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
  &.disable {
    opacity: 0.6;
    pointer-events: none;
  }
  &.default {
    background: #ffffff;
    color: #ff8506;
    border: 1px solid #ff8506;
  }
}
.tips {
  padding: 4px 12px;
  font-size: 12px;
}
.title {
  position: relative;
  &.line {
    padding-left: 10px;
  }
  &.line::before {
    content: "";
    width: 4px;
    height: 14px;
    background: #ff8506;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -7px;
  }
}

.page-container {
  min-height: 100vh;
}
.page-body {
  height: 100%;
  margin-top: 0;
  padding: 80px 0 15px;
  .content {
    position: relative;
    top: -14px;
    background: #fff;
    border-radius: 14px;
    padding: 20px 15px 35px;

    .section-title {
      display: flex;
      margin-bottom: 12px;
      .status {
        flex-shrink: 0;
        margin-right: 6px;
      }
      .title-style {
        display: inline-block;
        margin-top: 2px;
        padding: 2px 6px;
        background: linear-gradient(90deg, #FCB535, #FF8506);
        border-radius: 17px;
        color: #FBF9F8;
        font-size: 11px;
      }
      .title-txt {
        font-size: 15px;
        color: #444444;
        line-height: 1.8;
      }
    }
    .section-img {
      margin-bottom: 10px;
      img {
        width: 100%;
      }
    }
    .section-options {
      margin-bottom: 40px;
      .option-item {
        margin-bottom: 12px;
        color: #444444;
        .letter {
          display: inline-block;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 13px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, .2);
          margin-right: 8px;
          border-radius: 20px;
        }
        .icon-img {
          display: inline-block;
          width: 20px;
          margin-right: 8px;
          border-radius: 20px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, .2);
          vertical-align: top;
        }
        &.active {
          .letter {
            color: #fff;
            background: #FCA142;
          }
        }
      }
    }
    .btn-wrap {
      display: flex;
      justify-content: flex-end;
      margin: 40px 0 0;
      .btn {
        width: 70px;
        padding: 0;
        height: 30px;
        font-size: 14px;
        margin: 0 10px 0 0;
      }
    }
    .section-bottom {
      margin: 0 -15px;
      .explaintion {
        .wrap {
          padding: 0 15px;
        }
        .your-answer {
          height: 40px;
          line-height: 40px;
          color: #666;
          background: #F0EEEE;
          padding: 0 15px;
          margin-bottom: 20px;
        }
        .desc {
          color: #444444;
          line-height: 1.8;
        }
      }
    }
    .section-desc {
      display: flex;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 60px;
      left: 0;
      padding: 0 20px;
      line-height: 60px;
      border-top: 1px solid #E1E1E1;
      background: #ffffff;
      z-index: 10;
      img {
        width: 20px;
        margin-right: 8px;
      }
      .left {
        display: flex;
        align-items: center;
      }
      .right-wrap {
        display: flex;
      }
      .right {
        display: flex;
        align-items: center;
        margin-right: 12px;
        &.right-answer {
          color: #FF8506;
        }
        &.wrong-answer {
          color: #FF1A1A;
        }
      }
    }
  }

}

.popup-wrap {
  .section-desc {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      left: 0;
      padding: 0 20px;
      line-height: 60px;
      border-top: 1px solid #E1E1E1;
      background: #ffffff;
      z-index: 10;
      img {
        width: 20px;
        margin-right: 8px;
      }
      .left {
        display: flex;
        align-items: center;
      }
      .right-wrap {
        display: flex;
      }
      .right {
        display: flex;
        align-items: center;
        margin-right: 12px;
        &.right-answer {
          color: #FF8506;
        }
        &.wrong-answer {
          color: #FF1A1A;
        }
      }
    }
    .section-cicle {
      padding: 0 16px;
      .cicle-index {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #AAAAAA;
        border-radius: 50%;
        margin-right: 8px;
        margin-bottom: 8px;
        text-align: center;
        line-height: 40px;
        color: #666;
        &.active {
          background: #FF8506;
          color: #ffffff;
          border-color: transparent;
        }
      }
    }
}

.popup-content {
  width: 290px;
  // border-radius: 12px;
  overflow: hidden;
  background: #fff;
  &>.title {
    background: linear-gradient(90deg, #FCA142, #FF8506);
    padding: 15px;
    font-size: 18px;
    color: #fff;
    text-align: center;
  }
  .content {
    margin: 20px 0 10px;
    padding: 0 10px;
    font-size: 17px;
    text-align: center;
    .list {
      display: flex;
      align-items: center;
      margin-top: 10px;
      span {
        display: inline-block;
        width: 50%;
      }
    }
  }
  .btn-wrap {
    display: flex;
    padding: 15px;
    .btn {
      width: 40%;
      font-weight: 500;
    }
  }
}

</style>
